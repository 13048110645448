<template>
  <a-row :gutter="10">
    <a-col :span="6">
      <tooCategory @change="change"/>
    </a-col>
    <a-col :span="18">
      <toolList ref="list"/>
    </a-col>
  </a-row>
</template>

<script>
import tooCategory from './tooCategory/Index.vue'
import toolList from './toolList/Index.vue'

export default {
  name: 'Index',
  components: {
    tooCategory,
    toolList
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {
    change(e) {
      console.log(e)
      this.$refs.list.category_name = e.category_name
      this.$refs.list.queryParam.tc_id = e.tc_id
      this.$refs.list.handleRefresh(true)
    }
  }
}
</script>
