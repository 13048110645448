<template>
  <a-card :bordered="false">
    <div class="card-title">{{ category_name ? category_name + ' - ' : '' }}工具列表</div>
    <div class="table-operator">
      <a-button v-action:gllb_add type="primary" icon="plus" @click="handleAdd">新增</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        v-if="queryParam.tc_id"
        ref="table"
        rowKey="shipper_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :scroll="{x:true}"
        tableLayout="auto"
        :pageSize="15"
    >
      <div slot="tool_type" slot-scope="text, item">
        <a-tag v-if="text==0" color="#2db7f5">
          随车用品
        </a-tag>
        <a-tag v-if="text==1" color="#f50">
          燃油
        </a-tag>
        <a-tag v-if="text==2" color="#108ee9">
          尿素
        </a-tag>
      </div>
      <div slot="price" slot-scope="text, item">
        <span style="color: #d00505">¥{{ item.price }} / {{ item.tool_unit }}</span>
      </div>
      <div slot="inventory" slot-scope="text, item">
        <span v-if="text<=0" style="color: red">{{ text }}{{ item.tool_unit }}</span>
        <span v-else>{{ text }}{{ item.tool_unit }}</span>
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag color="green" v-if="text===0">启用</a-tag>
        <a-tag color="red" v-else>停用</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <a v-action:gllb_approve @click="handleShowApproveModel(item)">领取记录</a>
        <a v-action:gllb_edit @click="handleEdit(item)">编辑</a>
        <a v-action:gllb_delete @click="handleDelete(item)">删除</a>
      </div>
    </s-table>
    <SaveForm :tc_id="queryParam.tc_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
    <ApproveModel ref="ApproveModel" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/tool'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm.vue'
import SearchForm from './modules/SearchForm.vue'
import ApproveModel from './modules/ApproveModel.vue'

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm,
    ApproveModel
  },
  data() {
    return {
      category_name: '',
      // 查询参数
      queryParam: {
        tc_id: ''
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: '记录ID',
        //   dataIndex: 'tool_id'
        // },
        {
          title: '工具类型',
          dataIndex: 'tool_type',
          scopedSlots: {customRender: 'tool_type'}
        },
        {
          title: '工具名称',
          dataIndex: 'tool_name',
        },
        {
          title: '工具单价',
          dataIndex: 'price',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: '剩余库存',
          dataIndex: 'inventory',
          scopedSlots: {customRender: 'inventory'}
        },
        {
          title: '采购地点',
          dataIndex: 'purchase_address',
        },
        {
          title: '启用状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '添加时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {

    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    handleShowApproveModel(item) {
      this.$refs.ApproveModel.open(item.tool_id)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({toolId: item['tool_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
