<template>
  <a-card :bordered="false">
    <div class="card-title">工具分类</div>
    <div class="table-operator">
      <a-button v-action:gjfl_add type="primary" icon="plus" @click="handleAdd">新增</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="tc_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
        :scroll="{x:true}"
        tableLayout="auto"
        :customRow="customRow"
        :rowClassName="setRowClassName"
    >
      <div slot="status" slot-scope="text, item">
        <a-tag color="green" v-if="text===0">显示</a-tag>
        <a-tag v-else>隐藏</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <a v-action:gjfl_edit @click="handleEdit(item)">编辑</a>
        <a v-action:gjfl_delete @click="handleDelete(item)">删除</a>
      </div>
    </s-table>
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/tool/toolCategory'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm.vue'
import SearchForm from './modules/SearchForm.vue'

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'tc_id'
        // },
        {
          title: '分类名称',
          dataIndex: 'category_name',
        },
        {
          title: '启用状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        // {
        //   title: '添加时间',
        //   dataIndex: 'create_time'
        // },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              if (response.data.list.data.length > 0) {
                this.$nextTick(() => {
                  this.currentRow = response.data.list.data[0]
                  this.handleChange()
                })
              }
              return response.data.list
            })
      },
      currentRow: {},
      customRow: record => {
        return {
          on: { // 事件
            click: (event) => {
              this.currentRow = record
              this.handleChange()
            },       // 点击行
            dblclick: (event) => {
            },
            contextmenu: (event) => {
            },
            mouseenter: (event) => {
            },  // 鼠标移入行
            mouseleave: (event) => {
            }
          }
        }
      },
      setRowClassName: record => {
        return record === this.currentRow ? "clickRowStyl" : ''; //赋予点击行样式
      },
    }
  },
  created() {

  },
  methods: {
    handleChange() {
      this.$emit('change', this.currentRow)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({tc_id: item['tc_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
